export default {


    getDrivingArguments(id){
        return fetch("https://app.guidaevai.com/api/driving-arguments/" + id).then(res => res.json())
    },

    getDrivingErrors(id){
        return fetch("https://app.guidaevai.com/api/driving-errors/" + id).then(res => res.json())
    },

    getDrivingTrackings(id){
        return fetch("https://app.guidaevai.com/api/driving-trackings/" + id+ "/").then(res => res.json())
    },

    getPins(){
        return fetch("https://app.guidaevai.com/api/pins").then(res => res.json())
    }
    
    
    
    
}