// import React from 'react';
// import logo from './logo.svg';
// import './App.css';
// import Home from './home';
// import {
//     BrowserRouter as Router,
//     Switch,
//     Route,
//     Link,
//     useLocation
// } from "react-router-dom";



// export default function Routes() {
//     previousLocation = null
//     constructor(props) {
//         super(props);
//         this.previousLocation = this.props.location;
//     }

//     componentWillUpdate() {
//         const { location } = this.props;
//         if (!(location.state && location.state.modal)) {
//             this.previousLocation = this.props.location;
//         }
//     }

//     render() {
//         let location = useLocation();
//         const isModal = (
//             location.state &&
//             location.state.modal &&
//             this.previousLocation !== location
//         );

//         return (
//             <div>
//                 <Switch location={isModal ? this.previousLocation : location}>
//                     <Route exact path="/home">
//                         <Home />
//                     </Route>
//                     <Route exact path="/noteDetail">
//                         <NoteDetailModal />
//                     </Route>
//                 </Switch>
//                 {isModal
//                     ? <Route exact path="/noteDetail">
//                         <NoteDetailModal />
//                     </Route>
//                     : null
//                 }
//             </div>
//         );
//     }

// }

import React from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useHistory,
    useLocation,
    useParams
} from "react-router-dom";
import { Redirect } from "react-router";
import Home from "../screen/home/home";
import NoteDetailModal from '../screen/note-detail-modal/note-detail-modal';
import Lite from "../screen/lite/lite"

// This example shows how to render two different screens
// (or the same screen in a different context) at the same URL,
// depending on how you got there.
//
// Click the "featured images" and see them full screen. Then
// "visit the gallery" and click on the colors. Note the URL and
// the component are the same as before but now we see them
// inside a modal on top of the gallery screen.

export default function Routes() {
    return (
        <Router>
            <ModalSwitch />
        </Router>
    );
}

function ModalSwitch() {
    let location = useLocation();
    let history = useHistory();
    let background = location.state && location.state.background;
    if(location.pathname.includes('home')){
        let path = location.pathname.split('/');
        let newPath = "/"+path[2];
        return <Redirect to={newPath} />;
    }
    return (
        <div style={styles.container}>
            <div style={styles.containerBody}>
                <Switch location={background || location} history={history}>
                    <Route exact path="/:code">
                        <Home />
                    </Route>
                    <Route exact path="/:code/l">
                        <Lite />
                    </Route>
                    <Route exact path="/noteDetail">
                        <NoteDetailModal marker={location.state && location.state.marker} />
                    </Route>
                    {/* <Route exact path="/shareModal">
                        <ShareModal marker={location.state && location.state.marker} />
                    </Route> */}
                </Switch>

                {background &&
                    <Route exact path="/noteDetail">
                        <NoteDetailModal marker={location.state && location.state.marker} />
                    </Route>
                }
                {/* {background &&
                    <Route exact path="/shareModal">
                        <ShareModal marker={location.state && location.state.marker} />
                    </Route>
                } */}
            </div>
        </div>
    );
}

const styles = {
    container: {
        width: "100%",
        textAlign: "-webkit-center"
    },
    containerBody:{
    }
}
