import React from 'react';
import logo from './logo.svg';
import './App.css';
import Home from './screen/home/home';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useLocation
} from "react-router-dom";
import NoteDetailModal from './screen/note-detail-modal/note-detail-modal';
import Routes from './router/routes';

function App() {
    return (
        Routes()
    );
}

export default App;



