import React from 'react'
import Icon from '@mdi/react'
import { mdiPlayCircleOutline, mdiPauseCircleOutline } from '@mdi/js';


export default class GenericAudio extends React.Component {

    audioPlayed = false;

    constructor(props) {
        super(props)
        let audio = new Audio(this.props.url)
        this.state = {
            audio: audio,
            iconButton: mdiPlayCircleOutline
        }
    }

    componentWillUnmount(){
        this.audioPlayed = false;
        this.state.audio.pause();
        this.state.audio.removeEventListener('ended',()=>{
            this.audioEnded();
        })
        this.state.audio.removeEventListener('timeupdate',(event)=>{
            this.setProgressBar()
        })
    }

    componentDidMount(){
        this.state.audio.addEventListener('ended',()=>{
            this.audioEnded();
        })
        this.state.audio.addEventListener('timeupdate',(event)=>{
            this.setProgressBar()
        })
    }

    resetProgressBar(){
        var elem = document.getElementById("bar");
        elem.style.width = "0%";
    }

    setProgressBar() {
        var elem = document.getElementById("bar");
        var width = this.state.audio.currentTime;
        var totalTime = this.state.audio.duration;
        var currWidth = (width * 100) / totalTime
        elem.style.width = currWidth + "%";
    }

    playPauseAudio(){
        this.audioPlayed = !this.audioPlayed
        if(this.audioPlayed){
            this.state.audio.play()
            this.setState({iconButton: mdiPauseCircleOutline})
        }else{
            this.state.audio.pause();
            this.setState({iconButton: mdiPlayCircleOutline})
        }
    }

    audioEnded(){
        this.audioPlayed = false;
        this.setState({iconButton: mdiPlayCircleOutline})
        this.resetProgressBar();
    }

    render() {
        const color= "#F39200"
        return (
            <div style={styles.allContainer}>
                <div style={styles.controllerContainer}>
                    <button style={styles.btnPlay} onClick={() => this.playPauseAudio()}>
                    <Icon path={this.state.iconButton}
                    title="Note 2"
                    size={6}
                    color={color}
                />
                    </button>
                </div>
                <div style={styles.progressBar} id="progressBar">
                    <div style={styles.bar} id="bar"></div>
                </div>
            </div>
        )
    }

} 

const styles= {
    allContainer:{
        width:"100%",
        textAlign: "center"
    },
    progressBar: {
        width: "100%",
        backgroundColor: "#ddd"
    },
    bar: {
        width: "0%",
        height: "5px",
        backgroundColor: "#F39200"
    },
    controllerContainer: {
        display: "flex",
        flexDirection: "row",
        alignContent: "center",
        marginTop: 24,
        width: "100%",
        "justifyContent": "space-evenly"
    },
    btnPlay: {
        border: 0,
        background: "white",
        fontSize: 14
    },
    btnPause: {
        border: 0,
        background: "white",
        fontSize: 14
    }
}

