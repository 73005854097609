import React from 'react';
import ReactModal from 'react-modal';
import { pins } from "../../global"
import { withRouter } from "react-router-dom";
// import Playlist from 'react-mp3-player';
// import { Howl } from 'howler';
// import Sound from 'react-sound';
import GenericAudio from '../../components/audio';
import { mdiClose } from '@mdi/js';
import Icon from '@mdi/react'



ReactModal.setAppElement('#modal')
ReactModal.defaultStyles.overlay.backgroundColor = '#00000060';

export default withRouter(class NoteDetailModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    componentWillMount() {

    }

    startAudio(audio) {
        audio.play()
    }


    getValue() {
        switch (this.props.marker.type) {
            case pins.IMAGE:
                return (
                    <div style={modalStyles.imgStyle}>
                        <img src={this.props.marker.value} alt="" width="185px" height="185px"></img>
                    </div>
                )
            case pins.TEXT:
                return <p>{this.props.marker.value}</p>
            case pins.AUDIO:
                return <GenericAudio url={this.props.marker.value}></GenericAudio>
            case pins.ERROR:
                return (<div>
                    <img src={this.props.marker.value.image} alt="" width="100px" height="100px"></img>
                </div>)

            default:
                return null
        }
    }

    getTitle() {
        switch (this.props.marker.type) {
            case pins.IMAGE:
                return "Nota con immagine"
            case pins.TEXT:
                return "Nota testuale"
            case pins.AUDIO:
                return "Nota vocale"
            case pins.ERROR:
                return this.props.marker.value.text
            default:
                return null
        }
    }

    closeModal() {
        this.props.history.goBack()
    }

    render() {
        const value = this.getValue()
        const title = this.getTitle()
        return (
            <ReactModal
                isOpen={true}
                contentLabel="Minimal Modal Example"
                style={modalStyles}
            >
                <div style={modalStyles.headerContainer}>
                    <div style={modalStyles.titleContainer}>
                        <div style={modalStyles.titleLabel}>{title}</div>
                        <button style={modalStyles.btnClose} onClick={() => this.closeModal()}>
                            <Icon path={mdiClose}
                                title="Emoticon 0"
                                size={1}
                                color="#FFFFFF"
                            />
                        </button>
                    </div>
                </div>
                <div style={modalStyles.valueContainer}>
                        {value}
                </div>
                <div style={modalStyles.streetContainer}>
                    <a href={"http://www.google.com/maps?layer=c&cbll=" + this.props.marker.lat + "," + this.props.marker.lng} target="_blank">
                        <button style={modalStyles.btnStreet}>
                            <div style={modalStyles.streetLabel}> Vai a Street View </div>
                        </button>
                    </a>
                </div>
                {/* <div style={modalStyles.container}>
                    <div style={modalStyles.headerContainer}>
                        <div style={modalStyles.titleLabel}>{title}</div>
                        <div style={modalStyles.closeContainer}>
                            <button style={modalStyles.btnClose} onClick={() => this.closeModal()}>
                                <Icon path={mdiClose}
                                    title="Emoticon 0"
                                    size={1}
                                />
                            </button>
                        </div>

                    </div>

                    <div style={modalStyles.valueContainer}>
                        {value}
                    </div>
                    <div style={modalStyles.streetContainer}>
                        <a href={"http://www.google.com/maps?layer=c&cbll=" + this.props.marker.lat + "," + this.props.marker.lng} target="_blank">
                            <button style={modalStyles.btnStreet}> 
                            <div style={ modalStyles.streetLabel }> Vai a Street View </div>
                            </button>
                        </a>
                    </div>
                </div> */}
            </ReactModal>)
    }

})

const modalStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '300px',
        height: '300px'

    },
    container: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },
    btnClose: {
        width: "fit-content",
        border: 0,
        background: "#F39200",
        marginLeft: "auto",
        marginRight: "12px",
        zIndex: "9999999"
    },
    btnStreet: {
        width: "fit-content",
        background: "white",
        padding: "8px 12px",
        borderRadius: 8,
        border: "2px solid #F39200"
    },
    closeContainer: {
        display: "flex",
        flexDirection: "row",
        alignSelf: "flex-end"
    },
    streetContainer: {
        width: "100%",
        marginBottom: "15px",
        display: "flex",
        justifyContent: "center"
    },
    streetLabel: {
        marginLeft: 8,
        color: '#F39200',
        fontSize: "18px",
        fontWeight: "normal",
        fontFamily: "Open Sans, Sans-serif",
    },
    valueContainer: {
        width:"100%",
        height:"180px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin:"40px auto"
    },
    headerContainer: {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        backgroundColor: "#F39200",
        height: "50px"
    },
    imgStyle:{
        height:"100%",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    titleContainer:{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        height: "100%",
        width: "100%"
    },
    titleLabel: {
        color: "#FFFFFF",
        marginLeft: "12px",
        fontSize: "18px",
        fontWeight: "normal",
        fontFamily: "Open Sans, Sans-serif",
    }


};