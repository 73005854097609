import React from 'react';
import MapContainer from '../google-maps/gmap';
import { withRouter } from "react-router-dom";
import api from "../../api/api";
import Loader from 'react-loader-spinner'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Icon from '@mdi/react'
import { mdiStar } from '@mdi/js';
import { pins } from "../../global"
import MediaQuery from 'react-responsive'
import { FacebookShareButton, WhatsappShareButton } from "react-share";
import copy from 'copy-to-clipboard';
import { ToastsContainer, ToastsStore, ToastsContainerPosition } from 'react-toasts';

export default withRouter(class Home extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            notes: [],
            tracking: null,
            points: [],
            code: null,
            liteUrl: ""
        }

    }

    componentDidMount() {
        this.setState({ isLoading: true })
        let url = window.location.href + "/l"
        this.setState({ liteUrl: url })
        const { match: { params } } = this.props;
        if (params && params.code) {
            let code = params.code
            this.setState({ code: code })
            this.getTrackings(code)
        } else {
            console.log("no code")
        }
    }

    openNoteDetail(note) {
        this.props.history.push({
            pathname: '/noteDetail',
            state: {
                background: this.props.location,
                marker: note
            }
        })
    }

    copyToClipboard() {
        copy(this.state.liteUrl)
        ToastsStore.info("Link copiato negli appunti.")
    }

    getTrackings(code) {
        api.getDrivingTrackings(code).then(tracking => {
            if (tracking) {
                var notes = this.getNotes(tracking.pin_set)
                var points = this.deserializePoint(tracking.path)
                this.setState({
                    tracking: tracking,
                    notes: notes,
                    points: points,
                    isLoading: false
                })
            } else {
                console.log("Error get trackings")
            }
        },
            error => {
                console.log("Error get trackings")
            })
    }

    getNotes(pinSet) {
        var pinSetOrder = []
        var notes = []
        const index = pinSet.findIndex(pin => pin.order === 1)
        if (index > - 1) {
            for (var i = 0; i < pinSet.length; i++) {
                var pinFound = pinSet.find(pin => pin.order === i)
                if (pinFound) {
                    pinSetOrder.push(pinFound)
                }
            }
        } else {
            pinSetOrder = pinSet
        }
        pinSetOrder.forEach(marker => {
            var value = this.getNoteValue(marker)
            var note = {
                type: marker.type,
                value: value,
                lat: parseFloat(marker.latitude),
                lng: parseFloat(marker.longitude)
            }
            notes.push(JSON.parse(JSON.stringify(note)))
        })
        return notes
    }

    deserializePoints(pointsStr) {
        var pointsTmp = ''
        var points = []
        if (pointsStr && pointsStr.length > 0) {
            pointsTmp = pointsStr
            while (pointsTmp.length > 2) {
                const indexPO = pointsTmp.indexOf("[")
                const indexPC = pointsTmp.indexOf("]")
                //se ci sono una parantesi [ e una ] proseguo
                if (indexPO > -1 && indexPC > -1 && indexPO < indexPC) {
                    const strCopy = pointsTmp.replace("[", "").replace("]", "");
                    const indexFirst = pointsTmp.indexOf(",");
                    var indexSecond = pointsTmp.indexOf(",", (indexFirst + 1));
                    if (indexSecond === -1) {
                        indexSecond = pointsTmp.length
                    }
                    const lat = parseFloat(strCopy.substring(0, indexFirst - 1))
                    const lng = parseFloat(strCopy.substring(indexFirst, indexSecond - 2))
                    const point = {
                        lat: lat,
                        lng: lng
                    }
                    points.push(JSON.parse(JSON.stringify(point)))
                    pointsTmp = pointsTmp.slice(indexSecond + 1)
                } else {
                    pointsTmp = ''
                }
            }
        }
        return points
    }

    deserializePoint(pointsStr) {
        var pointsTmp = ''
        var points = []
        if (pointsStr && pointsStr.length > 2) {
            pointsTmp = pointsStr.trim()
            const indexPO = pointsTmp.indexOf("[")
            const indexPC = pointsTmp.indexOf("]")
            const firstIndex = pointsTmp.startsWith("[")
            const lastIndex = pointsTmp.endsWith("]")
            //se ci sono una parantesi [ e una ] proseguo
            if (indexPO > -1 && indexPC > -1 && indexPO < indexPC && firstIndex && lastIndex) {
                pointsTmp = pointsStr.slice(1, pointsStr.length - 1)
                const strCopy = pointsTmp.split("],[")
                for (var i = 0; i < strCopy.length; i++) {
                    let coord = strCopy[i]
                    const firstFound = coord.indexOf(",");
                    var secondFound = coord.indexOf(",", (firstFound + 1))
                    var vel = null
                    if (secondFound === -1) {
                        secondFound = coord.length
                    } else {
                        vel = parseFloat(coord.substring(secondFound))
                    }
                    const lat = parseFloat(coord.substring(0, firstFound))
                    const lng = parseFloat(coord.substring(firstFound + 1, secondFound))
                    var point = {
                        lat: lat,
                        lng: lng,
                        vel: vel
                    }
                    points.push(JSON.parse(JSON.stringify(point)))
                }
            }
        }

        return points
    }



    getNoteValue(note) {
        switch (note.type) {
            case pins.IMAGE:
                return note.image
            case pins.TEXT:
                return note.text
            case pins.AUDIO:
                return note.audio
            case pins.ERROR:
                var image = null
                if (note.error.image && note.error.image.trim() !== '') {
                    image = note.error.image.trim()
                }
                return { image: image, text: note.error.text }
            default:
                return null
        }
    }

    getHistoryIcon(note) {
        switch (note.type) {
            case pins.IMAGE:
                return require("../../res/img/marker_foto.png")
            case pins.TEXT:
                return require("../../res/img/marker_testo.png")
            case pins.AUDIO:
                return require("../../res/img/marker_audio.png")
            case pins.ERROR:
                return require("../../res/img/marker_errore.png")
            default:
                return null
        }
    }

    getHistoryLabel(note) {
        switch (note.type) {
            case pins.IMAGE:
                return "Visualizza immagine"
            case pins.TEXT:
                return "Leggi nota"
            case pins.AUDIO:
                return "Riproduci audio"
            case pins.ERROR:
                return "Visualizza errore"
            default:
                return null
        }
    }



    getRatingWithIcon(rating) {
        const color = "#FFFFFF"
        switch (rating) {
            case 1:
                return (<div>
                    ★
                </div>
                )
            case 2:
                return (<div>
                    ★★
                </div>)
            case 3:
                return (<div>
                    ★★★
                </div>)
            case 4:
                return (<div>
                    ★★★★
                </div>)
            case 5:
                return (<div>
                    ★★★★★
                </div>)

            default:
                return <div style={styles.reatingEmpty}> Nessun voto assegnato</div>
        }
    }

    getRatingWithIconMax(rating) {
        const color = "#FFFFFF"
        switch (rating) {
            case 1:
                return (<div>
                    ★
                </div>
                )
            case 2:
                return (<div>
                    ★★
                </div>)
            case 3:
                return (<div>
                    ★★★
                </div>)
            case 4:
                return (<div>
                    ★★★★
                </div>)
            case 5:
                return (<div>
                    ★★★★★
                </div>)

            default:
                return <div style={styles.reatingEmpty}> Nessun voto assegnato</div>
        }
    }

    getRatingWithLabel(rating) {
        switch (rating) {
            case 1:
                return "Insufficiente"
            case 2:
                return "Sufficiente"
            case 3:
                return "Mediocre"
            case 4:
                return "Buona"
            case 5:
                return "Ottima"

            default:
                return ""
        }
    }

    render() {
        if (this.state.isLoading) {

            return (
                <div style={styles.loaderContainer}>
                    <Loader
                        type="Oval"
                        color="#F39200"
                        height={40}
                        width={40}
                        timeout={500}
                    />
                </div>
            )

        }

        const noteList =
            this.state.notes.map((note, index) => {
                var label = this.getHistoryLabel(note)
                var icon = this.getHistoryIcon(note)
                return (
                    <div key={index}>
                        <div style={styles.noteTimeline} onClick={() => this.openNoteDetail(note)}>
                            <img src={icon} alt="" style={styles.noteImgStyle}></img>
                            <span style={styles.noteText}>{label}</span>
                        </div>
                        <div style={styles.interNote}>

                        </div>
                    </div>
                )
            })
        const noteListMax =
            this.state.notes.map((note, index) => {
                var label = this.getHistoryLabel(note)
                var icon = this.getHistoryIcon(note)
                return (
                    <div key={index}>
                        <div style={styles.noteTimelineMax} onClick={() => this.openNoteDetail(note)}>
                            <img src={icon} alt="" style={styles.noteImgStyleMax}></img>
                            <span style={styles.noteTextMax}>{label}</span>
                        </div>
                        <div style={styles.interNoteMax}>

                        </div>
                    </div>
                )
            })

        const rating = this.getRatingWithIcon(this.state.tracking.rating)

        const ratingMax = this.getRatingWithIconMax(this.state.tracking.rating)

        const ratingLabel = this.getRatingWithLabel(this.state.tracking.rating)

        const argument = this.state.tracking.driving_arguments.map((argument, index) => {
            var arg = ""
            arg = argument.text.toLowerCase();
            //arg = arg.charAt(0).toUpperCase() + arg.slice(1);
            return arg + ", ";
        })

        var student = 'Studente sconosciuto'
        if (this.state.tracking.student_name && this.state.tracking.student_name.trim() != '') {
            student = this.state.tracking.student_name.split(" ")[0]
        }

        var note = 'Nessuna nota inserita'
        if (this.state.tracking.note && this.state.tracking.note.trim() != '') {
            note = this.state.tracking.note
        }

        const header = (
            <section style={styles.bgLayer}>
                <div style={styles.headerImgLayer}>

                </div>
                <div style={styles.bottomShape}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none" style={styles.svgCurve}>
                        <path d="M500,97C126.7,96.3,0.8,19.8,0,0v100l1000,0V1C1000,19.4,873.3,97.8,500,97z"></path>
                    </svg>
                </div>
                <div style={styles.titleContainerFir}></div>
            </section>
        )

        const title = (
            <section>
                    <div style={styles.titleContainerSecMax}>
                        <div style={styles.titleContainerThiMax}>
                            <div style={styles.titleContainerFouMax}>
                                <div style={styles.titleContainerFifMax}>
                                    <div style={styles.titleContainerSixMax}>
                                        <div style={styles.titleContainerSevMax}>
                                            <h3 style={styles.labelValueHeader}>GUIDA E IMPARA</h3>
                                        </div>
                                    </div>
                                    <div style={styles.titleContainerEigMax}>
                                        <div style={styles.titleContainerNinMax}>
                                            <div style={styles.titleContainerTenMax}>
                                                <div style={styles.titleSpacerMax}></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={styles.titleContainerEleMax}>
                                        <div style={styles.titleContainerTweMax}>
                                            <h3 style={styles.labelValueHeaderSub}>REPORT DI GUIDA</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </section>
        )

        const headerMax = (
            <section style={styles.bgLayer}>
                <div style={styles.headerImgLayerMax}>

                </div>
                <div style={styles.bottomShape}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none" style={styles.svgCurve}>
                        <path d="M500,97C126.7,96.3,0.8,19.8,0,0v100l1000,0V1C1000,19.4,873.3,97.8,500,97z"></path>
                    </svg>
                </div>
                <div style={styles.titleContainerFir}></div>
                {/* <div style={styles.titleContainerFirMax}>
                    <div style={styles.titleContainerSecMax}>
                        <div style={styles.titleContainerThiMax}>
                            <div style={styles.titleContainerFouMax}>
                                <div style={styles.titleContainerFifMax}>
                                    <div style={styles.titleContainerSixMax}>
                                        <div style={styles.titleContainerSevMax}>
                                            <h3 style={styles.labelValueMaxHeader}>GUIDA E IMPARA</h3>
                                        </div>
                                    </div>
                                    <div style={styles.titleContainerEigMax}>
                                        <div style={styles.titleContainerNinMax}>
                                            <div style={styles.titleContainerTenMax}>
                                                <div style={styles.titleSpacerMax}></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={styles.titleContainerEleMax}>
                                        <div style={styles.titleContainerTweMax}>
                                            <h3 style={styles.labelValueMaxHeaderSub}>REPORT DI GUIDA</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
            </section>
        )

        const footer = (
            <div style={styles.footerContainer}>
                <div style={styles.midContainer}>
                    <div style={styles.flexContainerColumnFooter}>
                        <div style={styles.footerLabel}><b>Guida e Impara</b> è un prodotto dell'ecosistema <b>Guida e Vai.</b> Copyright © 2020 - <a href="http://reddoak.com" style={styles.aFooter}>Reddoak</a> - P.IVA 08831060960</div>
                    </div>
                </div>
            </div>
        )
        const footerMax = (
            <div style={styles.footerContainerMax}>
                <div style={styles.midContainerMax}>
                    <div style={styles.flexContainerColumnFooterMax}>
                        <div style={styles.footerLabelMax}><b>Guida e Impara</b> è un prodotto dell'ecosistema <b>Guida e Vai.</b> Copyright © 2020 - <a href="http://reddoak.com" style={styles.aFooter}>Reddoak</a> - P.IVA 08831060960</div>
                    </div>
                </div>
            </div>
        )

        const shareSection = (
            <div style={styles.shareContainer}>
                <div style={styles.midContainer}>
                    <div style={styles.flexContainerColumnShare}>
                        <span style={styles.shareText}>CONDIVIDI I TUOI RISULTATI</span>
                        <p style={styles.shareP}> Fai sapere a tutti i risultati che stai raggiungendo!</p>
                    </div>
                    <div style={styles.flexContainerRowShare}>
                        <FacebookShareButton style={styles.btnShareFacebook} url={this.state.liteUrl}><img src={require("../../res/img/facebook.png")} alt="" style={styles.imgShare}></img></FacebookShareButton>
                        <WhatsappShareButton style={styles.btnShareWhatsapp} url={this.state.liteUrl}><img src={require("../../res/img/whatsapp.png")} alt="" style={styles.imgShare}></img></WhatsappShareButton>
                        <div style={styles.btnShareCopy} onClick={() => this.copyToClipboard()}><img src={require("../../res/img/copy.png")} alt="" style={styles.imgShare}></img></div>
                    </div>
                </div>
            </div>
        )
        const shareSectionMax = (
            <div style={styles.shareContainerMax}>
                <div style={styles.midContainerMax}>
                    <div style={styles.flexContainerColumnShareMax}>
                        <span style={styles.shareTextMax}>CONDIVIDI I TUOI RISULTATI</span>
                        <p style={styles.sharePMax}> Fai sapere a tutti i risultati che stai raggiungendo!</p>
                    </div>
                    <div style={styles.flexContainerRowShareMax}>
                        <FacebookShareButton style={styles.btnShareFacebook} url={this.state.liteUrl}><img src={require("../../res/img/facebook.png")} alt="" style={styles.imgShare}></img></FacebookShareButton>
                        <WhatsappShareButton style={styles.btnShareWhatsapp} url={this.state.liteUrl}><img src={require("../../res/img/whatsapp.png")} alt="" style={styles.imgShare}></img></WhatsappShareButton>
                        <div style={styles.btnShareCopy} onClick={() => this.copyToClipboard()}><img src={require("../../res/img/copy.png")} alt="" style={styles.imgShare}></img></div>
                    </div>
                </div>
            </div>
        )

        const homeContainer = (
            <div style={styles.allContainer}>
                <img src="/user.svg" id="profilepic" alt="" style={styles.profilePic}></img>
                <div style={styles.infoContainer}>
                    <div style={styles.midContainer}>
                        <div style={styles.flexContainerColumnStudent}>
                            <div style={styles.labelValue}>{student}</div>
                            <div style={styles.nameRatingSpacerMax}></div>
                            <div style={styles.labelValue}>{rating}</div>
                        </div>
                        <div style={styles.flexContainerColumnIcon}>
                            <div style={styles.label}>GUIDA {ratingLabel.toUpperCase()}</div>
                            <p style={styles.argumentP}> {student} ha eseguito la guida pratica esercitandosi su {argument} raggiungendo la valutazione di Guida {ratingLabel}.</p>
                        </div>
                    </div>
                </div>
                <div style={styles.midContainer}>
                    <div style={styles.flexContainerColumnList}>
                        <h3 style={styles.labelList}> NOTE ISTRUTTORE </h3>
                        <div style={styles.flexListNote}>
                            {noteList}
                            <div style={styles.noteTimeline}>
                                <img src={require("../../../src/res/img/finish.svg")} alt="" style={styles.noteImg}></img>
                                <span style={styles.noteTextFinish}>FINE</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>)

        const homeContainerMax = (
            <div style={styles.allContainer}>
                <img src="/user.svg" id="profilepic" alt="" style={styles.profilePic}></img>
                <div style={styles.infoContainer}>
                    <div style={styles.midContainerMax}>
                        <div style={styles.flexContainerColumnStudentMax}>
                            <div style={styles.labelValueMax}>{student}</div>
                            <div style={styles.nameRatingSpacerMax}></div>
                            <div style={styles.labelValueMax}>{ratingMax}</div>
                        </div>
                        <div style={styles.flexContainerColumnIconMax}>
                            <div style={styles.labelMax}>GUIDA {ratingLabel.toUpperCase()}</div>
                            <p style={styles.argumentPMax}> {student} ha eseguito la guida pratica esercitandosi su {argument} raggiungendo la valutazione di Guida {ratingLabel}.</p>
                        </div>
                    </div>
                </div>
                <div style={styles.midContainer}>
                    <div style={styles.flexContainerColumnListMax}>
                        <h3 style={styles.labelListMax}> NOTE ISTRUTTORE </h3>
                        <div style={styles.flexListNoteMax}>
                            {noteListMax}
                            <div style={styles.noteTimeline}>
                                <img src={require("../../../src/res/img/finish.svg")} alt="" style={styles.noteImgMax}></img>
                                <span style={styles.noteTextFinishMax}>FINE</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>)

        return (
            <div>
                <MediaQuery minDeviceWidth={724}>
                    <div>
                        {header}
                        {title}
                        <div style={styles.mapContainer}>
                            <MapContainer markers={this.state.notes} points={this.state.points} />
                        </div>
                        {/* <MapContainer /> */}
                        {homeContainer}
                        {shareSection}
                        {footer}
                    </div>
                </MediaQuery>
                <MediaQuery maxDeviceWidth={724}>
                    <div>
                        {headerMax}
                        {title}
                        <div style={styles.mapContainerMax}>
                            <MapContainer markers={this.state.notes} points={this.state.points} />
                        </div>
                        {/* <MapContainer /> */}
                        {homeContainerMax}
                        {shareSectionMax}
                        {footerMax}
                    </div>
                </MediaQuery>
                <ToastsContainer store={ToastsStore} position={ToastsContainerPosition.BOTTOM_CENTER} />
            </div>
        )

    }


})

const styles = {
    allContainer: {
        width: "100%"
    },
    profilePic: {
        width: "128px",
        height: "128px",
        borderRadius: "50%",
        position: "relative",
        top: "200px"
    },
    midContainer: {
        maxWidth: "684px",
        margin: "0 auto"
    },
    infoContainer: {
        width: "100%",
        backgroundColor: "#F39200",
        minHeight: "463px",
        height: "100%",
        marginTop: '130px'
    },
    flexContainerColumn: {
        display: "flex",
        flexDirection: "column",
        textAlign: "left",
        marginLeft: 14,
    },
    mapContainer: {
        height: '500px',
        overflow: 'hidden',
        //border: '1px solid red',
        marginTop: '110px',
        width: '1100px'
    },
    flexContainerColumnIconArgomenti: {
        display: "flex",
        flexDirection: "row",
        marginTop: 30,
        marginBottom: 30,
        paddingBottom: 30,
        textAlign: "left",
        marginLeft: 0,
        // alignItems: "center"
        //justifyContent:'space-around',
        alignItems: 'center',
    },
    flexContainerColumnIcon: {
        display: "flex",
        flexDirection: "column",
        marginTop: 40,
        marginBottom: 30,
        paddingBottom: 30,
        textAlign: "center",
        marginLeft: 0,
        width: 644,
        // alignItems: "center"
        //justifyContent:'space-around',
        alignItems: 'center',
    },
    flexContainerColumnStudent: {
        display: "flex",
        flexDirection: "column",
        marginBottom: 30,
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: 90,
        paddingBottom: 30,
        width: 644,
        borderBottom: '3px solid #FFFFFF'
    },
    flexContainerRow: {
        display: "flex",
        flexDirection: "column",
        textAlign: "left",
        marginTop: 6,
        flexWrap: "wrap",
        //justifyContent:'space-around'
    },
    label: {
        // height: 0,
        color: '#FFFFFF',
        marginLeft: "auto",
        marginRight: "auto",
        fontSize: "25px",
        fontWeight: "bold",
        fontFamily: "Open Sans, Sans-serif",
        lineHeight: "30px"
    },
    argumentP: {
        color: '#FFFFFF',
        marginLeft: "auto",
        marginRight: "auto",
        fontSize: "25px",
        fontWeight: "normal",
        fontFamily: "Open Sans, Sans-serif",
        lineHeight: "30px",
        marginTop: 0
    },
    labelStudent: {
        marginBottom: 0,
        marginLeft: 15,
        marginRight: 40,
        color: '#FFFFFF',
        // marginTop: 22,
        fontSize: "0.95 rem",
        fontWeight: "bold",
        fontFamily: "Open Sans, Sans-serif",
    },
    labelList: {
        // height: 0,
        //margin: 0,
        color: '#F39200',
        marginTop: "90px",
        marginLeft: "auto",
        marginRight: "auto",
        marginBottom: "45px",
        fontWeight: "bold",
        fontSize: "37px",
        fontStyle: "normal",
        fontFamily: "Open Sans, Sans-serif",
    },
    labelArgument: {
        height: 0,
        // margin: 0,
        color: '#FFFFFF',
        fontSize: "0.95 rem",
        marginLeft: 15,
        marginRight: 40,
        fontWeight: "bold",
        fontFamily: "Open Sans, Sans-serif",
    },
    labelValueRadius: {
        marginTop: 6,
        // marginRight: 6,
        // padding: '6px',
        // border: '1px solid black',
        // borderRadius: '12px',
        width: 'fit-content',
        color: '#FFFFFF',
        fontSize: "1.1rem",
        //marginTop: 12,
        fontFamily: "Open Sans, Sans-serif",
        fontWeight: "normal",
        //textTransform: "capitalize"
    },
    labelValue: {
        // height: 0,
        color: '#FFFFFF',
        fontSize: "55px",
        //marginTop: 12,
        fontFamily: "Open Sans, Sans-serif",
        fontWeight: "bold",
        margin: "0 auto"
        // marginTop: 30
    },
    flexContainerRowIcon: {
        display: "flex",
        flexDirection: "row",
        textAlign: "left",
        alignItems: "center"
    },
    labelValueList: {
        color: '#3C3C3B'
        // marginLeft: 12
    },
    flexContainerRowList: {
        marginBottom: 8,
        display: "flex",
        flexDirection: "row",
        alignItems: "center"
    },
    flexListNote: {
        display: "flex",
        flexDirection: "column",
        width: "fit-content",
        marginTop: 50,
        marginBottom: 22
        // textAlign: "left"
    },
    flexContainerColumnList: {
        display: "flex",
        flexDirection: "column",
        textAlign: "left",
        marginLeft: 14
        // marginTop: 22
    },
    reatingEmpty: {
        // height: 0,
        color: '#FFFFFF',
        fontSize: "1.1rem",
        marginTop: 12,
        fontFamily: "Roboto Condensed"
    },
    ratingLabel: {
        color: '#FFFFFF',
        fontSize: "1.1rem",
        fontFamily: "Open Sans, Sans-serif",
        fontWeight: "normal",
        marginLeft: 15,
        marginRight: 40,
    },
    noteContainer: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginTop: 8,

    },
    noteLabel: {
        marginLeft: 8,
        color: '#3C3C3B',
        fontSize: "1.0rem",
        marginRight: 4,
        fontWeight: 500,
        fontFamily: "Roboto Condensed"
    },
    titleContainerFir: {
        display: "flex",
        marginRight: "auto",
        marginLeft: "auto",
        position: "relative",
        alignItems: "flex-end",
        minHeight: "290px"
    },
    // headerContainer: {
    //     textAlign: "center",
    //     width: "100%",
    //     top: 0,
    //     marginBottom: 12,
    //     height: "300px",
    //     //backgroundColor: "#F39200",
    //     backgroundImage : "url(https://mediafull.it/wp/wp-content/uploads/2020/03/1.jpg)",
    //     backgroundSize : "contain",
    //     backgroundPosition : "center center",
    //     backgroundRepeat: "no-repeat",
    //     // borderRadius:"0 0 100% 100%/ 0 0 15% 15%",
    //     // transform:"scaleX(2.5)"
    //     opacity: "1",
    //     transition: "background 0.3s, border-radius 0.3s, opacity 0.3s"
    // },
    // headerLabel: {
    //     fontSize: 24,
    //     fontFamily: "Roboto Condensed",
    //     fontWeight: 500,
    //     color: "#F39200"
    // },
    footerContainer: {
        height: 240,
        left: 0,
        bottom: 0,
        width: "100%",
        backgroundColor: "#3C3C3B",
        textAlign: "center"
    },
    footerBody: {

    },
    footerLabel: {
        fontSize: 20,
        lineHeight: "22px",
        color: "#FFFFFF",
        fontStyle: "normal",
        clear: "both",
        letterSpacing: "-0.02em",
        fontFamily: "Open Sans, Sans-serif",
    },
    btnNote: {
        border: "2px solid #F39200",
        borderRadius: 8,
        background: "white",
        padding: "8px 12px",
        marginTop: 12
    },
    loaderContainer: {
        // width: "100%",
        // textAlign: "center",
        position: "absolute",
        left: "50%",
        top: "50%"
    },
    noteImgStyle: {
        width: 62,
        height: 72,
        marginRight: "13px",
        cursor: "pointer"
    },
    noteImg: {
        width: 62,
        height: 62,
        marginRight: "13px"
    },
    noteTimeline: {
        display: "flex",
        alignItems: "center",
    },
    noteText: {
        color: "#3C3C3B",
        fontFamily: "Open Sans, Sans-serif",
        fontSize: "30px",
        fontWeight: "bold",
        fontStyle: "normal",
        textTransform: "uppercase",
        cursor: "pointer"
    },
    noteTextFinish: {
        color: "#A8A8A7",
        fontFamily: "Open Sans, Sans-serif",
        fontSize: "30px",
        fontWeight: "bold",
        fontStyle: "normal",
        textTransform: "uppercase"
    },
    interNote: {
        height: 50,
        width: 0,
        border: "2px dashed #a8abaf",
        marginLeft: 29
    },
    shareContainer: {
        width: "100%",
        height: 352,
        backgroundColor: "#EEEEEE"
    },
    flexContainerColumnShare: {
        display: "flex",
        flexDirection: "column",
        marginBottom: 30,
        marginTop: 100,
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: 70,
        width: 684,
    },
    shareText: {
        color: "#3C3C3B",
        fontFamily: "Open Sans, Sans-serif",
        fontWeight: "bold",
        fontStyle: "normal",
        fontSize: "37px"
    },
    shareP: {
        color: '#3C3C3B',
        marginLeft: "auto",
        marginRight: "auto",
        fontSize: "25px",
        fontStyle: "normal",
        fontFamily: "Open Sans, Sans-serif",
        lineHeight: "30px"
    },
    flexContainerRowShare: {
        display: "flex",
        flexDirection: "row",
        justifyContent: 'center',
        width: 684,
    },
    btnShare: {
        padding: "15px",
        height: 60,
        width: 60,
        marginRight: 15
    },
    btnShareCopy: {
        height: 60,
        width: 60,
        marginRight: 15,
        borderRadius: "10%",
        outline: "none",
        backgroundColor: "#7A7A7A",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer"
    },
    btnShareWhatsapp: {
        height: 60,
        width: 60,
        marginRight: 15,
        borderRadius: "10%",
        outline: "none",
        backgroundColor: "#25D366",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    btnShareFacebook: {
        height: 60,
        width: 60,
        marginRight: 15,
        borderRadius: "10%",
        outline: "none",
        backgroundColor: "#3B5998",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    imgShare: {
        height: "24px",
        width: "24px"
    },
    flexContainerColumnFooter: {
        display: "flex",
        flexDirection: "column",
        marginBottom: 30,
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: 95,
        width: 684,
    },
    aFooter: {
        color: "#FFFFFF",
        textDecoration: "none"
    },
    labelValueHeader: {
        color: '#434341',
        fontSize: "20px",
        fontFamily: "Open Sans, Sans-serif",
        fontWeight: "normal",
        margin: "0 auto",
        fontStyle: "normal"
    },
    labelValueHeaderSub: {
        color: '#434341',
        fontSize: "37px",
        fontFamily: "Open Sans, Sans-serif",
        fontWeight: "bold",
        margin: "0 auto",
        fontStyle: "normal"
    },
    // flexContainerColumnHeader:{
    //     display: "flex",
    //     flexDirection: "column",
    //     justifyContent:'center',
    //     alignItems:'center', 
    //     paddingTop: 200,
    //     width: "100%",
    // },
    /**************** MOBILE VERSION  ********************/
    mapContainerMax: {
        height: 'auto',
        marginTop: '100px',
        width: '90%'
    },
    midContainerMax: {
        maxWidth: "90%",
        margin: "0 auto"
    },
    flexContainerColumnStudentMax: {
        display: "flex",
        flexDirection: "column",
        marginBottom: 30,
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: 90,
        paddingBottom: 25,
        width: "100%",
        borderBottom: '3px solid #FFFFFF'
    },
    labelValueMax: {
        color: '#FFFFFF',
        fontSize: "45px",
        fontFamily: "Open Sans, Sans-serif",
        fontWeight: "bold",
        margin: "0"
    },
    flexContainerColumnIconMax: {
        display: "flex",
        flexDirection: "column",
        marginTop: 40,
        marginBottom: 30,
        paddingBottom: 30,
        textAlign: "center",
        marginLeft: 0,
        width: "100%",
        alignItems: 'center',
    },
    labelMax: {
        // height: 0,
        color: '#FFFFFF',
        marginLeft: "auto",
        marginRight: "auto",
        fontSize: "20px",
        fontWeight: "bold",
        fontFamily: "Open Sans, Sans-serif",
        lineHeight: "22px"
    },
    argumentPMax: {
        color: '#FFFFFF',
        marginLeft: "auto",
        marginRight: "auto",
        fontSize: "20px",
        fontWeight: "normal",
        fontFamily: "Open Sans, Sans-serif",
        lineHeight: "22px",
        marginTop: 0
    },
    flexContainerColumnListMax: {
        display: "flex",
        flexDirection: "column",
        textAlign: "left",
        marginLeft: 14
    },
    labelListMax: {
        color: '#F39200',
        marginTop: "70px",
        marginLeft: "auto",
        marginRight: "auto",
        marginBottom: "45px",
        fontWeight: "bold",
        fontSize: "30px",
        fontStyle: "normal",
        fontFamily: "Open Sans, Sans-serif",
    },
    flexListNoteMax: {
        display: "flex",
        flexDirection: "column",
        width: "fit-content",
        marginTop: 50,
        marginBottom: 22
    },
    noteTimelineMax: {
        display: "flex",
        alignItems: "center",
    },
    noteImgStyleMax: {
        width: 54,
        height: 62,
        marginRight: "13px"
    },
    noteTextMax: {
        color: "#3C3C3B",
        fontFamily: "Open Sans, Sans-serif",
        fontSize: "20px",
        fontWeight: "bold",
        fontStyle: "normal",
        textTransform: "uppercase"
    },
    interNoteMax: {
        height: 30,
        width: 0,
        border: "2px dashed #a8abaf",
        marginLeft: 25
    },
    noteImgMax: {
        width: 54,
        height: 54,
        marginRight: "13px"
    },
    noteTextFinishMax: {
        color: "#A8A8A7",
        fontFamily: "Open Sans, Sans-serif",
        fontSize: "20px",
        fontWeight: "bold",
        fontStyle: "normal",
        textTransform: "uppercase"
    },
    shareContainerMax: {
        width: "100%",
        height: 389,
        backgroundColor: "#EEEEEE"
    },
    flexContainerColumnShareMax: {
        display: "flex",
        flexDirection: "column",
        marginBottom: 30,
        marginTop: 100,
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: 70,
        width: "100%",
    },
    shareTextMax: {
        color: "#3C3C3B",
        fontFamily: "Open Sans, Sans-serif",
        fontWeight: "bold",
        fontStyle: "normal",
        fontSize: "30px"
    },
    sharePMax: {
        color: '#3C3C3B',
        marginLeft: "auto",
        marginRight: "auto",
        fontSize: "20px",
        fontStyle: "normal",
        fontFamily: "Open Sans, Sans-serif",
        lineHeight: "25px"
    },
    flexContainerRowShareMax: {
        display: "flex",
        flexDirection: "row",
        justifyContent: 'center',
        width: "100%",
    },
    footerContainerMax: {
        height: 256,
        left: 0,
        bottom: 0,
        width: "100%",
        backgroundColor: "#3C3C3B",
        textAlign: "center"
    },
    flexContainerColumnFooterMax: {
        display: "flex",
        flexDirection: "column",
        marginBottom: 30,
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: 95,
        width: "100%",
    },
    footerLabelMax: {
        fontSize: 15,
        lineHeight: "22px",
        color: "#FFFFFF",
        fontStyle: "normal",
        clear: "both",
        letterSpacing: "-0.02em",
        fontFamily: "Open Sans, Sans-serif",
    },
    labelValueMaxHeader: {
        color: '#FFFFFF',
        fontSize: "20px",
        fontFamily: "Open Sans, Sans-serif",
        fontWeight: "normal",
        margin: "0",
        padding: "0",
        fontStyle: "normal",
        lineHeight: "1",
        clear: "both",
        letterSpacing: "-0.02em"
    },
    labelValueMaxHeaderSub: {
        color: '#FFFFFF',
        fontSize: "30px",
        fontFamily: "Open Sans, Sans-serif",
        fontWeight: "bold",
        margin: "0",
        padding: "0",
        lineHeight: "1",
        clear: "both",
        letterSpacing: "-0.02em",
        fontStyle: "normal"
    },
    bgLayer: {
       // backgroundImage: 'url("/header_certificato.jpg")',
        position: "relative",
        height: "300px",
        display: "flex",
        justifyContent: "center",
        background: "linear-gradient(#f29206, #f9b131)",
        height: "60%",
        width: "100%",
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        transition: "background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s"
    },
    headerImgLayer: {
        backgroundImage: 'url("/header_certificato.jpg")',
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
        opacity: 1,
        height: "100%",
        width: "80%",
        position: "absolute",
        display: "block",
        transition: "background 0.3s, border-radius 0.3s, opacity 0.3s"
    },
    headerImgLayerMax: {
        backgroundImage: 'url("/header_certificato.jpg")',
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        opacity: 1,
        height: "100%",
        width: "100%",
        top: 0,
        left: 0,
        position: "absolute",
        display: "block",
        transition: "background 0.3s, border-radius 0.3s, opacity 0.3s"
    },
    bottomShape: {
        overflow: "hidden",
        position: "absolute",
        left: 0,
        width: "100%",
        lineHeight: 0,
        direction: "ltr",
        display: "block",
        bottom: "-1px"
    },
    svgCurve: {
        width: "calc(120% + 1.3px)",
        height: "60px",
        transition: "fill 120ms ease-in-out",
        fill: "white",
        display: "block",
        position: "relative",
        left: "50%",
        transform: "translateX(-50%)",
    },
    titleContainerFirMax: {
        display: "flex",
        marginRight: "auto",
        marginLeft: "auto",
        position: "relative",
        alignItems: "flex-end",
        minHeight: "270px"
    },
    titleContainerSecMax: {
        display: "flex",
        width: "100%",
        flexWrap: "flex",
        marginTop: "24px"
    },
    titleContainerThiMax: {
        display: "flex",
        width: "100%",
        position: "relative",
        minHeight: "1px"
    },
    titleContainerFouMax: {
        display: "flex",
        position: "relative",
        width: "100%",
        transition: "background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s"
    },
    titleContainerFifMax: {
        alignItems: "center",
        alignContent: "center",
        display: "flex",
        flexWrap: "wrap",
        width: "100%"
    },
    titleContainerSixMax: {
        textAlign: "center",
        width: "100%",
        marginBottom: "0px",
        position: "relative"
    },
    titleContainerSevMax: {
        margin: "0px 0px 0px 0px",
        transition: "background .3s,border .3s,border-radius .3s,box-shadow .3s,-webkit-border-radius .3s,-webkit-box-shadow .3s",
    },
    titleContainerEigMax: {
        position: "relative",
        marginBottom: "0px",
        width: "100%"
    },
    titleContainerNinMax: {
        display: "block",
        transition: "background .3s,border .3s,border-radius .3s,box-shadow .3s,-webkit-border-radius .3s,-webkit-box-shadow .3s"
    },
    titleContainerTenMax: {
        display: "block"
    },
    titleSpacerMax: {
        display: "block",
        height: "5px"
    },
    titleContainerEleMax: {
        display: "block",
        marginBottom: "opx",
        position: "relative",
        width: "100%",
        textAlign: "center"
    },
    titleContainerTweMax: {
        margin: "0px 0px 0px 0px",
        display: "block",
        transition: "background .3s,border .3s,border-radius .3s,box-shadow .3s,-webkit-border-radius .3s,-webkit-box-shadow .3s"
    },
    nameRatingSpacerMax: {
        height: "15px",
        display: "block",
        width: "100%"
    }
}