import React from 'react';
import { GoogleMap, Marker, Polyline, withGoogleMap } from "react-google-maps";
import { compose } from 'recompose';
import { withRouter } from "react-router-dom";
import { pins } from '../../global';
import MediaQuery from "react-responsive";

var initMap = false
var mapGuide = null

class MapContainer extends React.Component {

    // due array attesi:

    // 1) array di punti (già approssimati da Google)
    // esempio: [ { lat: 40.537732, lng: 17.435812 }, { lat: 40.5377180, lng: 17.4360165 }, { lat: 40.5384333, lng: 17.4369959 }

    // 2) array di Marker (con lat, lng, tipo e valore):
    // { lat: 40.537732, lng: 17.435812, type: 1, value: "Fine della guida" }
    //nel caso si tratti di type 3 (Errore) il campo value sarà
    // value: {image: "url", text: "text"}

    modalIsOpens = false

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            trackings: [],
            bounds: null,
            modalIsOpen: false,
            noPoints: false,
            markers: [],
            markerStart: {
                lat: null,
                lng: null,
                type: 1,
                value: "Inizio della guida"
            },
            markerEnd: {
                lat: null,
                lng: null,
                type: 1,
                value: "Fine della guida"
            },
            points: [
                // { lat: 40.537732, lng: 17.435812 },
                // { lat: 40.5377180, lng: 17.4360165 },
                // { lat: 40.5384333, lng: 17.4369959 },
                // { lat: 40.5391789, lng: 17.4379730 },
                // { lat: 40.5393009, lng: 17.4378178 },
                // { lat: 40.537747, lng: 17.435521 },
                // { lat: 40.526600, lng: 17.588467 }
            ]
        };

        mapGuide = this.getMapGuide()
    }

    componentWillMount(){
        mapGuide = null
        initMap = false
        mapGuide = this.getMapGuide()
    }

    componentDidMount() {
        this.setState({ isLoading: true })
        if (this.props.points && this.props.points.length > 0) {
            this.setState({
                markers: this.props.markers,
                points: this.props.points
            }, () => {
                let bounds = new window.google.maps.LatLngBounds()
                for (var i = 0; i < this.state.points.length; i++) {
                    bounds.extend(this.state.points[i]);
                }
                this.setState({ bounds: bounds })
                this.initMarkers()
            })

        } else {
            this.setState({noPoints: true})
        }
        this.setState({ isLoading: false })
    }


    initMarkers() {
        let markerStart = this.state.markerStart
        markerStart.lat = this.state.points[0].lat
        markerStart.lng = this.state.points[0].lng

        let markerEnd = this.state.markerEnd
        markerEnd.lat = this.state.points[this.state.points.length - 1].lat
        markerEnd.lng = this.state.points[this.state.points.length - 1].lng

        this.setState({ markerStart: markerStart, markerEnd: markerEnd })
    }

    // setPoints(){
    //     this.setState({points: this.props.points})
    // }

    setIcon(type) {
        switch (type) {
            case pins.IMAGE:
                return require("../../res/icon/map/marker/foto-marker.png")
            case pins.TEXT:
                return require("../../res/icon/map/marker/text-marker.png")
            case pins.AUDIO:
                return require("../../res/icon/map/marker/audio-marker.png")
            case pins.ERROR:
                return require("../../res/icon/map/marker/error-marker.png")
            default:
                return null
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (initMap && this.state.points === nextState.points) {
            return false
        }
        return true
    }

    openMarkerDetail(marker) {
        this.props.history.push({
            pathname: '/noteDetail',
            state: {
                background: this.props.location,
                marker: marker
            }
        })
    }

    getMapGuide() {
        if (mapGuide === null) {
            return (compose(withGoogleMap)
                (props => <div>
                    <GoogleMap
                        defaultZoom={8} //with fitBounds ignored but mandatory
                        defaultCenter={{ lat: -34.397, lng: 150.644 }} //with fitBounds ignored but mandatory
                        defaultOptions={{ styles: mapStyles }}
                        onResize= {()=>{
                            mapGuide = null;
                            initMap = false;
                            this.getMapGuide()
                        }}
                        ref={(map) => {
                            if (map && !initMap) {
                                map.fitBounds(this.state.bounds)
                                initMap = true
                            }
                        }}
                    >
                        {this.state.points &&
                            <Polyline
                                path={this.state.points}
                                defaultOptions={{
                                    strokeColor: "#FF7400",
                                    strokeOpacity: 0.8,
                                    strokeWeight: 5
                                }} />
                        }

                        {this.state.markerStart &&
                            <Marker
                                // onClick={() => this.openMarkerDetail(this.state.markerStart)}
                                name={this.state.markerStart.name}
                                position={{ lat: this.state.markerStart.lat, lng: this.state.markerStart.lng }}
                                icon={require("../../res/icon/map/start-icon.png")}
                            >

                            </Marker>
                        }

                        {this.state.markerEnd &&
                            <Marker
                                // onClick={() => this.openMarkerDetail(this.state.markerEnd)}
                                name={this.state.markerEnd.name}
                                position={{ lat: this.state.markerEnd.lat, lng: this.state.markerEnd.lng }}
                                icon={require("../../res/icon/map/finish-icon.png")}
                            >
                            </Marker>
                        }

                        {this.state.markers.length > 0 && this.state.markers.map((marker, index) => {
                            let iconUrl = null
                            iconUrl = this.setIcon(marker.type)
                            return <Marker
                                key={index}
                                onClick={() => this.openMarkerDetail(marker)}
                                name={marker.name}
                                position={{ lat: marker.lat, lng: marker.lng }}
                                icon={{ url: iconUrl, scaledSize: { width: 35, height: 40 } }} >
                            </Marker>
                        }
                        )}

                    </GoogleMap>
                </div>

                ));
        }

        return null

    }


    render() {
        const MapGuide = mapGuide

        if (this.state.isLoading) {
            return (
                <div>
                    <p> Loading Google Map...</p>
                </div>
            )
        }
        if (this.state.noPoints) {
            return (
                <div>
                    <p> Nessun percorso tracciato </p>
                </div>
            )
        }
        return (
            <div>
                <MediaQuery minDeviceWidth={1024}>
                    <div>
                        <MapGuide
                            containerElement={<div style={{ height: "520px" }} />}
                            mapElement={<div style={{ height: "100%" }} />}
                        />
                    </div>
                </MediaQuery>
                <MediaQuery maxDeviceWidth={1024}>
                    <div>
                        <MapGuide
                            containerElement={<div style={{ height: "400px" }} />}
                            mapElement={<div style={{ height: "100%" }} />}
                        />
                    </div>
                </MediaQuery>
            </div>
        )
    }
}

const mapStyles = require("./GoogleMapStyles.json")

export default withRouter(props => <MapContainer {...props} />)
